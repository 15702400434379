import React from "react";
import carAnimation from './carfind.json';
import Lottie from 'react-lottie';
import Button from "@material-ui/core/Button";
import {useHistory} from 'react-router-dom';
export default function (props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: carAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const history = useHistory();
    return(<div  style={{backgroundColor:'white'}}>
      <div  className={'container'}>
          <div className={'row'}>
             <div  className={'col'} style={{textAlign:'center'}}>
              <Lottie options={defaultOptions} width={400} height={400} />
              <h3 style={{marginBottom:'20px'}}>{props.message ? props.message : "Het lijkt erop dat je een verkeerde afslag nam. "}</h3>
               <Button  style={{marginBottom:'20px'}} onClick={()=>{history.push('/')}} variant="contained" color="primary">Ga naar de startpagina</Button>
             </div>
          </div>
      </div>
    </div>)
}

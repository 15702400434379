import { useEffect } from 'react';

const useScript = (url,id,deleteScript = false) => {
	useEffect(() => {
		if(deleteScript){
			if(document.getElementById(id)){

				document.getElementById(id).remove();
			}
			if(	document.getElementById("conv132415")){
				document.getElementById("conv132415").remove();
			}
			return;
		}
		if( document.getElementById(id) == null)
		{
			const script = document.createElement('script');
			script.src = url;
			script.async = true;
			script.id=id;
			document.body.appendChild(script);

			return () => {
				document.body.removeChild(script);
			}
		}

	}, [url,deleteScript]);
};

export default useScript;

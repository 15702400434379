import React, {useEffect, useState} from "react";
import BodyContainer from "../Common/BodyContainer/BodyContainer";
import CustomButton from "../Common/CustomButton/CustomButton";
import {useHistory} from 'react-router-dom';
import useScript from "../Common/Utilities/useScript";


export default function (props) {
	const history = useHistory();
	useScript('https://app.convertful.com/Convertful.js?owner=31039',"convertful-api");
	const renderLocation = (permalink)=>{
		props.reactGA.event({
			category: "Location",
			action: "Select",
			label:permalink
		})
		history.push(`/${props.brand.link}/${props.brandCondition}/${permalink}${history.location.search}`);
	}
	const [images,setImages] = useState([]);
	useEffect(()=>{
		let newImages = [];
		if(props.fleetLocations != null){
			let width = props.fleetLocations.length * 10
			if(props.fleetLocations.length === 1){
				width = '50'
			}
			props.fleetLocations.map(item=>{
				newImages.push({
					url: item.image,
					title: item.name,
					permalink:item.permalink,
					width:`${width}%`,
					renderFunction : renderLocation
				})
				return item;
			})
		}else{
			let width = props.brand.locations.length * 10
			if(props.brand.locations.length === 1){
				width = '50'
			}
			props.brand.locations.map(item=>{
				newImages.push({
					url: item.image,
					title: item.name,
					width:`${width}%`,
					permalink:item.permalink,
					renderFunction : renderLocation
				})
				return item;
			})
		}
		setImages(newImages);
	},[props.brand.locations])



	return(<React.Fragment>
		<BodyContainer {...props} childComponent={<React.Fragment>
			<React.Fragment>
				<div className={'col'}>
					<h4>{props.brand.title_location ? props.brand.title_location : 'Waar word je graag verder geholpen?'}</h4>
				</div>
				<CustomButton carCondition={props.brandCondition} className={'margin-images'}  images={images}/>
			</React.Fragment>
		</React.Fragment>
		} />
	</React.Fragment>)
}

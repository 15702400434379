import React, {useEffect, useState} from "react";
import BodyContainer from "../Common/BodyContainer/BodyContainer";
import CustomButton from "../Common/CustomButton/CustomButton";
import {useHistory} from 'react-router-dom';
import useScript from "../Common/Utilities/useScript";
import { Typography } from "@material-ui/core";


export default function (props) {
	const history = useHistory();
	useScript('https://app.convertful.com/Convertful.js?owner=31039',"convertful-api");
	const renderLocation = (permalink)=>{
		props.reactGA.event({
			category: "Location",
			action: "Select",
			label:permalink
		})
		if (permalink === 'new') {
			history.push(`/${props.brand.link}/new`);
		} else {
			history.push(`/${props.brand.link}/fleet`);
		}
		
	}
	const [images,setImages] = useState([
		{
			url: props.brand.fleet_background_image,
			title: props.brand.fleet_button_proceed ? props.brand.fleet_button_proceed : 'Ga door',
			permalink:'',
			width:`45%`,
			renderFunction : renderLocation
		},
		{
			url: props.brand.new_background_image,
			title:  props.brand.fleet_button_new_cars ? props.brand.fleet_button_new_cars : 'Boek afspraak voor bedrijfswagen',
			permalink:'new',
			width:`45%`,
			renderFunction : renderLocation
		},
	]);
	return(<React.Fragment>
		<BodyContainer {...props} childComponent={<React.Fragment>
			<React.Fragment>
				<div className={'col'}>
					<h4>{props.brand.fleet_page_title ? props.brand.fleet_page_title : 'Ben jij een vlootbeheerder?'}</h4>
						{props.brand.fleet_page_text ? 
						<Typography align="center" dangerouslySetInnerHTML={{__html: props.brand.fleet_page_text}} /> : <Typography >
						Via deze weg maak je een afspraak bij onze verkopers gespecialiseerd in fleet. Ben je een wagenparkverantwoordelijke? Klik dan op <b>ga door</b>! <br/>
					Ben je eerder op zoek naar je volgende bedrijfswagen? Kies je <b>bedrijfswagen</b>.</Typography>}
				<br/>
				</div>
				<CustomButton carCondition={props.brandCondition} className={'margin-images'}  images={images}/>
			</React.Fragment>
		</React.Fragment>
		} />
	</React.Fragment>)
}

import React, {useEffect, useState} from "react";
import {carBrands} from "../../AppConstants";
import BodyContainer from "../Common/BodyContainer/BodyContainer";
import {useHistory, useLocation} from "react-router-dom";
import CustomButton from "../Common/CustomButton/CustomButton";
import useScript from "../Common/Utilities/useScript";
import Button from "@material-ui/core/Button";

export default function (props) {
    const history = useHistory();
    const location = useLocation();
    useScript('https://app.convertful.com/Convertful.js?owner=31039',"convertful-api");
    const renderBrandCars = (carType)=>{
        props.reactGA.event({
            category: "Appointment Type",
            action: "Select",
            label:carType
        })
        history.push(`${props.brand.link}/${carType}${history.location.search}`,{carCondition:carType});

    }
    const [images,setImages] = useState([]);


    useEffect(()=>{
        setImages([
            {   url: props.brand.new_background_image,
                title: props.brand.button_new ? props.brand.button_new : 'New',
                width: '30%',
                permalink:'new',
                renderFunction:renderBrandCars
            },
            {
                url: props.brand.used_background_image,
                title: props.brand.button_used ? props.brand.button_used : 'Used',
                width: '30%',
                permalink:'used',
                renderFunction:renderBrandCars
            },
            {
                url: props.brand.fleet_background_image,
                title: props.brand.button_fleet ? props.brand.button_fleet :'Fleet',
                width: '30%',
                permalink: 'fleetwarning',
                description: props.brand.button_fleet_subtext ? props.brand.button_fleet_subtext : '(Enkel voor wagenparkverantwoordelijken)',
                renderFunction:renderBrandCars
            },
        ]);
    },[props.brand])
    useEffect(()=>{
        if(props.brand.title === carBrands.mini.title){
            props.switchTheme()
        }
    },[props.switchTheme])

    return(
    <React.Fragment>
        <BodyContainer {...props} childComponent={
            <React.Fragment>
                <div className={'col'}>
                    <h4>{props.brand.title_condition ? props.brand.title_condition : 'Voor uw verkoopafspraak'}</h4>
                </div>

                <CustomButton className={'margin-images'}  images={images}/>
            <div className={'col'} style={{display:'flex',width:'100%',justifyContent:'flex-end',marginTop:'20px'}}>
                <div style={{width:'351px',display:'flex'}}>
                    <div>
                        <h2 style={{fontSize:'1.3rem'}}>Onderhoud & Herstellingen</h2>
                        <p style={{fontSize:'0.9rem'}}>Plan een service afspraak in via de website of de 24/7 Service App.</p>
                    </div>

                    <div className={'col-6'}  style={{display:'flex',flexDirection:'column'}}>
                        <Button onClick={()=>{
                            window.open('https://serviceapp.bmw.be/public/login','_blank')
                        }} style={{backgroundColor:'#0C2E40',color:'white',marginBottom:'4px'}}>BMW Service Afspraak</Button>
                        <Button
                            onClick={()=>{
                                window.open('https://serviceapp.mini.be/public/login','_blank')
                            }}style={{backgroundColor:'#0C2E40',color:'white'}}>MINI Service Afspraak</Button>
                    </div>
                </div>
            </div>



            </React.Fragment>
        }/>
    </React.Fragment>
    )
}

import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		minWidth: 300,
		width: '100%',
		justifyContent:'space-around'
	},
	image: {
		position: 'relative',
		height: 200,
		[theme.breakpoints.down('xs')]: {
			width: '90% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover, &$focusVisible': {
			zIndex: 1,
			'& $imageBackdrop': {
				opacity: 0.15,
			},
			'& $imageMarked': {
				opacity: 0,
			},
			'& $imageTitle': {
				border: '4px solid currentColor',
			},
		},
	},
	focusVisible: {},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageSrc: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundSize: 'cover',
		backgroundPosition: 'center 40%',
	},
	imageBackdrop: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.4,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		fontWeight:'bolder',
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
}));

export default function (props) {
	const classes = useStyles();
	return(<div className={classes.root}>
		{props.images.map((image) => (
			 <ButtonBase
					focusRipple
					onClick={(e)=>image.renderFunction(image.permalink)}
					key={image.title}
					className={`${classes.image} ${props.className}`}
					focusVisibleClassName={classes.focusVisible}
					style={{
						width: image.width,
					}}>
				 <span className={classes.imageSrc} style={{backgroundImage: `url(${image.url})`}}/>
				 <span className={classes.imageBackdrop} />
				 <span className={classes.imageButton}>
                       <Typography
													component="span"
													variant="subtitle1"
													color="inherit"
													className={classes.imageTitle}>
                           {image.title}
												 <span className={classes.imageMarked} ></span>
	<Typography> {image.description}</Typography>
            </Typography>

					 <Typography></Typography>
          </span>
			 </ButtonBase>
		))}
	</div>
	)
}
